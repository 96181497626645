(function(){
  // Push banner details to dataLayer
  const track = (event, details) => {
    window.dataLayer?.push({
      event: event,
      trendemonBannerId: details.cta?.Id,
      trendemonBannerType: details.ctaEventPrefix,
      trendemonBannerName: details.cta?.Name,
      trendemonBannerTarget: details.cta?.Link,
    });
  };

  // Map Trendemon events to dataLayer events
  const events = {
    cta_shown: 'trendemon_banner_displayed',
    cta_close: 'trendemon_banner_closed',
    cta_complete: 'trendemon_banner_clicked',
  };

  Object.entries(events).forEach(([trendemon, datalayer]) => {
    document.addEventListener(trendemon, e => track(datalayer, e.detail))
  });
})();