/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/* global pw_script_vars */

function getParameterByName(name) {
  var regexS = "[\\?&]" + name + "=([^&#]*)",
    regex = new RegExp(regexS),
    results = regex.exec(window.location.search);
  if (results == null) {
    return "";
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

(function ($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.

var Sage = {
  // All pages
  'common': {
    init: function () {
      // Handle skip link, focus on the first focusable element inside the main content area.
      // $('.skip-link').on('click', function() {
      //   $('#main').find(focusableElementsSelector).eq(0).focus();
      // });

      const $body = $('body');
      const $window = $('window');

      function is_conversion_page(url) {
        if (url.indexOf('/read/') !== -1 || url.indexOf('/get/') !== -1 || url.indexOf('/hi/') !== -1 || url.indexOf('/lp/') !== -1) {
          return true;
        } else {
          return false;
        }
      }

      const currentDomain = window.location.hostname;
      const isCurrentPageConversion = is_conversion_page(window.location.href);
      $('a').not('.skip-link').each(function() {
        const url = $(this).attr('href');
        try {
          const domain = url && url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
          if (domain && domain[1] && domain[1] != currentDomain) { // Check if the link is on a different domain
            $(this).attr('target', '_blank');
          } else {
            const targetElement = $('#' + encodeURIComponent(url.split('#')[1]));
            if (url.indexOf('#') > -1 && targetElement.length) {
              $(this).on('click', function(e) {
                e.preventDefault();
                const targetElement = $('#' + encodeURIComponent(this.href.split('#')[1]));
                let offset = 4;
                let window_width = $(window).width();
                if (window_width > 767) {
                  offset = 74;
                }
                $('html, body').animate({
                  scrollTop: targetElement.offset().top - offset
                }, {
                  duration: 400,
                  easing: 'linear'
                });
              });
            } else if (isCurrentPageConversion || is_conversion_page(url)) {
              $(this).attr('target', '_blank');
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      });

      // formidable form submit add body class
      $(document).on('focus', 'input,select,textarea', function () {
        $('meta[name=viewport]').attr('content', 'width=device-width, initial-scale=1, maximum-scale=4');
        document.body.style.opacity = 0.9999;
        setTimeout(function () { document.body.style.opacity = 1; }, 100);
      });


      // pass UTM to register , but this only work
      $body.on('click', 'a[href="https://app.hibob.com/register"]', function () {
        $(this).attr('href', $(this).attr('href') + location.search);
      });


      //Logic for login page 2021
      let urlParams = new URLSearchParams(window.location.search);
      let utmUsed = false;
      let utm_referrals = {};
      ['utm_creative', 'utm_lp', 'utm_geo', 'utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content', 'utm_referrer', 'gclid', 'msclkid', 'fbclid', 'first_page_seen', 'ref', 'adid', 'ad_id', 'utm_partner', 'utm_agency', 'utm_influencer', 'utm_funnel', 'utm_obj', 'utm_campaignid', 'utm_adgroupid', 'site_source_name', 'campaign.name', 'campaign.id', 'adset.name', 'adset.id', 'ad.name', 'ad.id', 'placement'].forEach(function (param_name) {
        var val = urlParams.get(param_name);
        if (val === null || val === '') return;
        utmUsed = true;
      });
      let bobNoLogin = urlParams.get('bob-app-no-login');
      if (bobNoLogin || utmUsed) {
        setCookie("bob-app-no-login",1,60);
      }

      $('nav ul li.menu-item-has-children>a')
        .click(function (e) {
          e.preventDefault();
          const $this = $(this);
          $this.attr('aria-expanded', $this.attr('aria-expanded') === 'true' ? 'false' : 'true');
          $this.parent().toggleClass('show');
          $this.parent().children('ul').stop().slideToggle(300);
          return false;
        });


      jQuery.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results === null) {
          return null;
        }
        else {
          return results[1] || 0;
        }
      };

      function redirecttoLink(url, blank) {
        var redirect_url = url;
        var params       = '';
        var experiment   = getCookie('experiment');
        var utm_campaign = jQuery.urlParam('utm_campaign');
        var utm_content  = jQuery.urlParam('utm_content');
        var utm_medium   = jQuery.urlParam('utm_medium');
        var utm_source   = jQuery.urlParam('utm_source');
        var utm_term     = jQuery.urlParam('utm_term');
        var utm_lp       = jQuery.urlParam('utm_lp');
        var utm_geo      = jQuery.urlParam('utm_geo');
        var utm_creative = jQuery.urlParam('utm_creative');
        var append_url   = (redirect_url.indexOf("?") > -1) ? '&' : '?';

        $(document).find('a#test-redirect').remove();
        $('body').append('<a id="test-redirect" href="' + redirect_url + '" target="' + blank + '"></a>');
        if (experiment || utm_lp || utm_geo || utm_creative || utm_campaign !== null || utm_content !== null || utm_medium !== null || utm_source !== null || utm_term !== null) {
          redirect_url = redirect_url + append_url;
          params = { utm_mq_1:experiment, utm_lp: utm_lp, utm_geo: utm_geo, utm_creative: utm_creative, utm_campaign: utm_campaign, utm_content: utm_content, utm_medium: utm_medium, utm_source: utm_source, utm_term: utm_term };
          var str = jQuery.param(params);
          redirect_url = redirect_url + str;
          $('body').find('a#test-redirect').attr('href', redirect_url);
          $(document).find('a#test-redirect')[0].click();
          $(document).find('a#test-redirect').remove();
        } else {
          jQuery.ajax({
            type: 'POST',
            url: pw_script_vars.ajaxurl,
            async: false,
            dataType: "json",
            data: {
              action: 'get_utm_cookies_value'
            },
            success: function (utm_referrals) {
              if (utm_referrals) {
                redirect_url = redirect_url + append_url;
                var str = jQuery.param(utm_referrals);
                redirect_url = redirect_url + str;
                $('body').find('a#test-redirect').attr('href', redirect_url);
                $(document).find('a#test-redirect')[0].click();
              } else {
                $(document).find('a#test-redirect')[0].click();
              }
            }
          });
        }

      }

      $body.on('click touchstart', 'button[data-ga-action]', function (e) {
        if ((typeof ga === 'function' && ga.loaded))
          ga('send', 'event', 'interested', $(this).attr('data-ga-action'), $(this).attr('data-ga-label'));
      });

      $body.on('click', 'a[href^="http://"],a[href^="https://"]', function (event) {
        if (jQuery(this).not('#test-redirect, #registration-via-video').length > 0) {
          var url = this.href;
          var ga_event = jQuery(this).attr('data-ga-event');
          var ga_value = jQuery.trim(jQuery(this).text());
          var ga_action = jQuery(this).attr('data-ga-action');
          var location = 'content ';
          var ga_label = jQuery(this).attr('title');
          var data_ga_label = $(this).attr('data-ga-label');
          if (data_ga_label) {
            ga_label = data_ga_label;
          }

          if ($(this).parents('header').length > 0) {
            location = 'header';
          } else if ($(this).parents('footer').length > 0) {
            location = 'footer';
          } else if ($(this).parents('nav').length > 0) {
            location = 'menu';
          }

          if (url === 'https://blog.hibob.com/') {
            ga_event = 'interested';
          }

          if (
            (ga_event != null && ga_event !== '') ||
            $(this).parent().hasClass('try-bob-free') ||
            $(this).parents('#video-modal').length > 0) {
            event.preventDefault();
            event.stopPropagation();

            if (ga_event != null && ga_event !== '' && typeof ga === 'function' && ga.loaded) {
              ga('send', 'event', ga_event, ga_action, ga_value);
            }

            if (url.indexOf('hibob.com') !== -1 || url.indexOf('app.hibob.com') !== -1) {
              if (url.indexOf('app.hibob.com') !== -1) {
                redirecttoLink(url, '_blank');
              } else {
                if ($(this).attr('target') == '_blank') {
                  redirecttoLink(url, '_blank');
                } else {
                  redirecttoLink(url, '');
                }
              }
            } else {
              redirecttoLink(url, '_blank');
            }
          }
        }
      });

      $body.on('click', '.navbar-toggle', function (e) {
        const $this = $(e.target);
        const $navbarContainer = $this.closest('.nav-primary');
        const $header = $navbarContainer.closest('header');
        const $navbarCollapse = $navbarContainer.find('.navbarCollapse');
        const $navbarToggle = $navbarContainer.find('.navbar-toggle');

        // Show mobile menu
        if (!$navbarCollapse.hasClass('in')) {
          trapFocusNative($header.get(0), true);
          $navbarToggle.attr('aria-expanded', 'true');
          $navbarCollapse.addClass('in');
          $navbarContainer.addClass('in');
          $navbarContainer.find('.hamburger-svg').addClass('hidden-svg');
          $navbarContainer.find('.close-svg').removeClass('hidden-svg');
          $('body, html').addClass('menuopened');

          if (typeof(drift) !== 'undefined') {
            drift?.api?.widget.hide();
          }
        }

        // Hide mobile menu
        else {
          trapFocusNative($header.get(0), false);
          $navbarToggle.attr('aria-expanded', 'false');
          $navbarCollapse.removeClass('in');
          $navbarContainer.removeClass('in');
          $navbarContainer.find('.hamburger-svg').removeClass('hidden-svg');
          $navbarContainer.find('.close-svg').addClass('hidden-svg');
          $('body, html').removeClass('menuopened');

          if (typeof(drift) !== 'undefined') {
            drift?.api?.widget.show();
          }
        }
      });

      if ($body.find('video').length > 0) {
        $body.addClass('video');
      }

      // focus trap inside modals
      // [...document.querySelectorAll('.modal')].forEach( modal => trapFocus(modal));

      let topBannerTime = localStorage.getItem('close-top-banner');
      let currentTime = new Date().getTime() / 1000;
      if (topBannerTime == null || Number(currentTime) > Number(topBannerTime)) {
        $('#top-banner-container').fadeIn('500');
        if ($('#top-banner-container').length > 0) {
          $('header').addClass('top-banner-closed').attr('top-banner-exists', 'top-banner-closed');
        }
      } else {
        $('#top-banner-container').remove();
        $('header').removeClass('top-banner-closed').attr('top-banner-exists', '');
      }

      $('#top-banner-container .close-top-banner').click(function (e) {
        $('#top-banner-container').hide();
        $('header').removeClass('top-banner-closed');
        $('header').attr('top-banner-exists', '');
        localStorage.setItem('close-top-banner', new Date().getTime() / 1000 + 86400); // +1 day in seconds
      });

      //Munchkin events on modal open
      $('[data-target="#book-demo-modal"]').each(function() {
        $(this).on('click', function() {
          if (typeof Munchkin != 'undefined')
            Munchkin.munchkinFunction('clickLink', {
              'href': '/request-a-demo-modal'
              }
            );
        });
      });
      $('[data-target="#watch-modal"]').each(function() {
        $(this).on('click', function() {
          if (typeof Munchkin != 'undefined')
            Munchkin.munchkinFunction('clickLink', {
                'href': '/watch-a-demo-modal'
              }
            );
        });
      });
    },
    finalize: function () {
      // clone header for stickiness
      $('header.banner .try-bob-free a').attr('data-ga-label', 'header menu');
      $('header.banner')
        .clone()
        .addClass('showonscroll')
        .attr({ tabindex: '-1', 'aria-hidden': 'true' })
        .insertAfter('header.banner');
      $('header.banner.showonscroll .try-bob-free a').attr('data-ga-label', 'sticky header menu');

      const header_showonscroll = $('header.banner.showonscroll');
      // Prevent duplicate IDs
      header_showonscroll.find('[id]').each(function (i, el) {
        const $el = $(el);
        const new_id = `${el.id}-${i}`;
        $el.attr('id', new_id);
      });

      header_showonscroll.find('.try-bob-free a').attr('data-ga-label', 'sticky header menu');
      header_showonscroll.find('a[href], button').each((i, el) => {
        el.tabIndex = -1;
      });

      if ( typeof(A11y) !== 'undefined' && pw_script_vars.enable_accessibility_panel ) {
        window.a11y_panel = new A11y();
      }

      // Skip link will refer to the first div/main element after the header and assign its ID.
      const skip_link = document.querySelector('.skip-link');

      if (skip_link) {
        const container_after_header = [...document.querySelectorAll('header.banner')].find( header => header.nextElementSibling.nodeName !== 'HEADER' )?.nextElementSibling;
        const container_after_header_id = container_after_header.id !== '' ? container_after_header.id : container_after_header.id = 'mega-main-content';
        skip_link.href = container_after_header_id ? `#${container_after_header_id}` : skip_link.hash;
      }

      const close_sub_menu = function(link) {
        $(link).focus().removeClass('active').attr('aria-expanded', false);
      }

      const desktop_nav_links = $('.menu-main-menu > .menu-item.has-sub-menu > a');
      desktop_nav_links?.on( 'keydown', e => {
        const link = $(e.target);

        if ( e.shiftKey && e.keyCode === 9 ) {
          close_sub_menu(e.target);
          return;
        }

        // 'Enter' / 'Space'
        if ( ! [13, 32].includes(e.keyCode) ) {
          return;
        }

        e.preventDefault();
        desktop_nav_links.not(link).removeClass('active').attr('aria-expanded', false);
        link.toggleClass('active').attr('aria-expanded', link.hasClass('active'));
      });

      const submenu_desktop_links = $('.menu-main-menu > .menu-item.has-sub-menu .sub-menu-wrap').find('a, button');
      submenu_desktop_links?.on( 'keydown', e => {
        // 'Tab' or 'Escape'
        if (![9, 27].includes(e.keyCode)) {
          return;
        }

        const parent_menu = e.target.closest('.has-sub-menu');
        const parent_link = parent_menu.querySelector('a');

        if (e.keyCode === 27) {
          close_sub_menu(parent_link);
          return;
        }

        const links_in_submenu = parent_menu.querySelectorAll('.sub-menu-wrap :is(a, button)');

        if (links_in_submenu.length === 0) {
          return;
        }

        const last_link = links_in_submenu[links_in_submenu.length - 1];

        // If we're going forward, and we're on the last link in the submenu - close the submenu.
        // the browser will continue to the next root link.
        if (!e.shiftKey && e.target === last_link) {
          close_sub_menu(parent_link);
        }
      });

      //add lity popup for all images
      $(document).on('click', '[data-lightbox]', lity);

      var requestDemoCookie = getCookie('request_demo_variation');
      if ( requestDemoCookie === undefined || requestDemoCookie == null || requestDemoCookie.length <= 0 ) {
        var variant = Math.random();
        if ( variant < 0.5 ) {
          variant = Math.floor(variant)
        }
        else {
          variant = Math.ceil(variant)
        }
        requestDemoCookie = variant;
        setCookie('request_demo_variation', requestDemoCookie);
      }
      if ( requestDemoCookie ) {
        var buttMenuDemo = $('.menu-main-menu .try-bob-free button');
        buttMenuDemo.attr('data-toggle', '');
      }

      //Script for drift chat
      (function() {
        var drift_selector = "a[href*='#talktous']";
        function ready(fn) {
          if (document.readyState != 'loading') {
            fn();
          } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', fn);
          } else {
            document.attachEvent('onreadystatechange', function() {
              if (document.readyState != 'loading')
                fn();
            });
          }
        }
        function forEachElement(selector, fn) {
          var elements = document.querySelectorAll(selector);
          for (var i = 0; i < elements.length; i++)
            fn(elements[i], i);
        }
        function openChat(driftApi, event) {
          event.preventDefault();
          driftApi.widget.openChat();
          return false;
        }
        function openSidebar(driftApi, event) {
          event.preventDefault();
          driftApi.sidebar.open();
          return false;
        }
        function openTalkToUsSidebar(driftApi, event) {
          event.preventDefault();
          location.hash = 'talktous';
          driftApi.sidebar.open();
          return false;
        }
        function DrifOpenTalkToUsSidebar(){
          if(typeof drift !== "undefined"){
            drift.on('ready', function(api) {
                    var handleClick = openTalkToUsSidebar.bind(this, api);
                    forEachElement(drift_selector, function(el) {
                      el.addEventListener('click', handleClick);
                    });
            });
          } else {
              setTimeout(DrifOpenTalkToUsSidebar, 500);
          }
        }
        ready(function() {
          DrifOpenTalkToUsSidebar();
        });
      })();


      //scroll to section
      var hashValue = window.location.hash.substr(1);
      if (hashValue !== undefined && hashValue !== '' && jQuery('#' + hashValue).length) {
        jQuery("body, html").animate({
          scrollTop: jQuery('#' + hashValue).offset().top - 100
        }, 1200);
      }


      function fix_demo_button_ga_label(button) {
        var galabel = button.getAttribute('data-ga-label');
        if (!galabel) {
          galabel = 'content';
          if ($(button).closest('header.banner').length > 0) {
            galabel = 'header';
          }
          if ($(button).closest('section.hero').length > 0) {
            galabel = 'hero';
          }
        }
        return galabel;
      }

      $('[data-ga-action]').each(function (elm) {
        $(this).attr('data-ga-label', fix_demo_button_ga_label(this));
      });

      $('iframe[data-src]').each(function () {
        $(this).attr('src', $(this).attr('data-src'));
      });

      $('.lang-menu').click((e) => {
        e.preventDefault();
        console.log('lang menu fired');
        $(e.target).attr('aria-expanded', true);
        $('#lang-menu-modal').modal('show');
      });

      $('#lang-menu-modal').on('hidden.bs.modal', function(e) {
        $('button.lang-menu').attr('aria-expanded', false).trigger('focus');
      });

      /**
       * Trap focus on all modals.
       * Keep track on multiple open modals: apply focus on the previous opened modal after closing another.
       */
      const open_modals = [];
      $('.modal[role="dialog"]').each((i, modal) => {
        trapFocus(modal);

        $(modal)
          .on('shown.bs.modal', (e) => open_modals.push(e.target))
          .on('hidden.bs.modal', (e) => {
            open_modals.splice(open_modals.indexOf(e.target), 1);
            open_modals.length > 0 && open_modals[open_modals.length - 1].focus();
          });
      });

      $('.footer-cookie-setting-link a')
        .attr('id', 'footer-cookie-setting-link')
        .click((e) => {
          e.preventDefault();
          if (typeof(Optanon) !== 'undefined') {
            Optanon.ToggleInfoDisplay();
          }
        });
    }
  },
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function (func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function () {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);
}) (jQuery);

jQuery(document).ready(function($) {

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var headerheight = $('header.banner').outerHeight();
    var window_width = $(window).width();
    if (scroll > headerheight) {
      $('header').addClass('sticky').removeClass('top-banner-closed');
    } else {
      $('header').removeClass('sticky');
      var topBannerExists = $('header').attr('top-banner-exists');
      if (topBannerExists === 'top-banner-closed') {
        $('header').addClass('top-banner-closed');
      }
    }
  });

  var window_width_menu = $(window).width();
  if (window_width_menu > 768) {
    jQuery('#menu-mobile-menu>li').click(function () {
      if (jQuery(this).hasClass("active_sub_menu")) {
        jQuery(this).removeClass("active_sub_menu");
        jQuery(this).find('.sub-menu').hide();
      } else {
        jQuery(this).addClass("active_sub_menu");
        jQuery(this).find('.sub-menu').show();
      }
    });
  }
});
