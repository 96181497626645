(function ($) {
  $(document).ready(function () {
    const targetClasses = ['post-type', 'single-glossary', 'single-research', 'guides-type', 'single-hr-tools'];
    if (targetClasses.some(className => document.body.classList.contains(className))) {
      if (Math.random() < 0.1) {
        $.ajax({
          type: 'POST',
          url: pw_script_vars.ajaxurl,
          data: {
            action: 'add_post_view',
            post_id: pw_script_vars.page_id
          }
        }).done(( msg ) => {
          console.log(msg);
        });
      }
    }
  });
})(jQuery);