$(document).ready(function () {
  function slick_a11y(slick, breakpoint) {
    if ($(window).width() >= breakpoint) {
      setTimeout(() => {
        slick.$slides.attr({
          role: 'tabpanel',
          // tabindex: null,
          'aria-hidden': null,
          // 'aria-labelledby': null,
          'aria-describedby': null,
        });
      }, 100);
    }
  }

  function updateTabIndexBasedOnWidth($tabs) {
    var windowWidth = $(window).width();
    if (windowWidth < 768) {
        $tabs.attr('tabindex', '-1');
    } else {
        $tabs.filter('.active').attr('tabindex', '0');
    }
  }

  $('.pb-tabs').each(function() {
    var $carousel = $(this);
    var $tabs = $carousel.find('.tab');
    var $controlsWrap = $carousel.find('.pb-controls-wrap');
    const carouselInside = $carousel.hasClass('view-carousel') ? true : false;
    $controlsWrap.empty();

    $tabs.each(function(index) {
      var $tab = $(this);
      var tabId = $tab.data('tab-id');
      var tabLabel = $tab.data('tab-label');
      var ariaLabelledBy = $tab.attr('aria-labelledby');

      var $control = $('<div class="control"></div>');
      $control.attr('data-tab-id', tabId);
      $control.attr('data-tab-label', tabLabel);
      $control.attr('role', 'tab');
      $control.attr('id', ariaLabelledBy);
      if (index === 0) {
        $control.attr('aria-selected', 'true').attr('tabindex', '0');
      } else {
        $control.attr('aria-selected', 'false').attr('tabindex', '-1');
      }
      if (!carouselInside) {
        $control.attr('aria-controls', tabId);
      }
      $control.text(tabLabel);

      $controlsWrap.append($control);

      if (index === 0) {
        $tab.addClass('active').attr('tabindex', '0');
        $control.addClass('active');
      }

      $control.on('click', function() {
        $tabs.removeClass('active').attr('tabindex', '-1');
        $controlsWrap.find('.control').removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1');

        $(this).addClass('active').attr('aria-selected', 'true').attr('tabindex', '0');
        $carousel.find('.tab[data-tab-id="' + $(this).data('tab-id') + '"]').addClass('active').attr('tabindex', '0');

        var index = $controlsWrap.find('.control').index($(this));

        if (carouselInside) {
          if ($carousel.find('.pb-tabs-wrap').slick('getSlick')) {
            $carousel.find('.pb-tabs-wrap').slick('slickGoTo', index);
          }
        }

        $controlsWrap.scrollLeft($(this).position().left + $controlsWrap.scrollLeft() - ($controlsWrap.width() / 2 - $(this).outerWidth() / 2));
      });

      $control.on('keydown', function(e) {
        var $controls = $controlsWrap.find('.control');
        var currentIndex = $controls.index($(this));

        switch (e.key) {
          case 'ArrowRight':
            if (currentIndex === $controls.length - 1) {
              $controls.eq(0).click().focus();
            } else {
              $controls.eq(currentIndex + 1).click().focus();
            }
            break;
          case 'ArrowLeft':
            if (currentIndex === 0) {
              $controls.eq($controls.length - 1).click().focus();
            } else {
              $controls.eq(currentIndex - 1).click().focus();
            }
            break;
          case 'Home':
            e.preventDefault();
            $controls.eq(0).click().focus();
            break;
          case 'End':
            e.preventDefault();
            $controls.eq($controls.length - 1).click().focus();
            break;
        }
      });
    });

    if (carouselInside) {
      $carousel.find('.pb-tabs-wrap').on('init destroy', (e, slick) => slick_a11y(slick, 768)).slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
        variableWidth: false,
        autoplay: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              infinite: true,
              autoplay: false,
            }
          },
        ]
      });

      var $controls = $controlsWrap.find('.control');
      $carousel.find('.tab').each(function() {
        var $slide = $(this);
        var slideIdAttr = $slide.data('tab-id');
        var slideId = $slide.attr('id');

        if (slideId) {
          $controls.each(function() {
            var $control = $(this);
            var controlTabId = $control.data('tab-id');

            if (slideIdAttr === controlTabId) {
              $control.attr('aria-controls', slideId);
            }
          });
        }
      });

      $(window).on('resize orientationchange', function () {
        $carousel.find('.pb-tabs-wrap').slick('resize');
      });

      $carousel.find('.pb-tabs-wrap').on('afterChange', function(event, slick, currentSlide) {
        $controlsWrap.find('.control').removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1');
        var $activeControl = $controlsWrap.find('.control').eq(currentSlide);
        $activeControl.addClass('active').attr('aria-selected', 'true').attr('tabindex', '0');
        $tabs.removeClass('active').attr('tabindex', '-1');
        $tabs.eq(currentSlide).addClass('active').attr('tabindex', '0');
        $controlsWrap.scrollLeft($activeControl.position().left + $controlsWrap.scrollLeft() - ($controlsWrap.width() / 2 - $activeControl.outerWidth() / 2));
      });
    }

    if (!carouselInside) {
      updateTabIndexBasedOnWidth($tabs);

      $(window).on('resize orientationchange', function() {
          updateTabIndexBasedOnWidth($tabs);
      });
    }
  });
});