document.addEventListener('DOMContentLoaded', function () {
  const search_toggle = document.querySelector('#togglesearch');
  const open_button = document.querySelector('label[for="togglesearch"].open');
  const setToggle = (set_checked = true) => {
    if (set_checked) {
      search_toggle.checked = !search_toggle.checked;
    }

    if (search_toggle.checked) {
      open_button.setAttribute('aria-pressed', 'true');
    } else {
      open_button.setAttribute('aria-pressed', 'false');
      open_button.focus();
    }
  };

  document.querySelectorAll('label[for="togglesearch"]').forEach(el => {
    el.addEventListener('click', (e) => setToggle(false));
    el.addEventListener('keydown', (e) => e.keyCode === 13 && setToggle());
  });
});
